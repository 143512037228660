import { Suspense } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import 'sanitize.css/sanitize.css';
import './i18n';

import { resetCss } from './configs/reset-css';
import { theme } from './configs/theme';
import LandingPage from './pages/landingPage';
import Footer from './components/footer';

const App = () => {
  resetCss();

  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <Switch>
            <Route path="/" exact component={LandingPage} />
          </Switch>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};
export default App;
