import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './assets/styles';

const Contact: FC = () => {
  const { address, content, title, text } = useStyles();
  const { t } = useTranslation();
  const tPrefixAddress = 'landing.contact.address';
  const tPrefixDigital = 'landing.contact.digital';

  return (
    <section className={content}>
      <address className={address}>
        <Typography variant="caption" className={title}>
          {t(`${tPrefixAddress}.title`)}
        </Typography>
        <Typography variant="body1" className={text}>
          {t(`${tPrefixAddress}.text1`)}
        </Typography>
        <Typography variant="body1" className={text}>
          {t(`${tPrefixAddress}.text2`)}
        </Typography>
        <Typography variant="body1" className={text}>
          {t(`${tPrefixAddress}.text3`)}
        </Typography>
      </address>
      <aside>
        <Typography variant="caption" className={title}>
          {t(`${tPrefixDigital}.title`)}
        </Typography>
        <a href="mailto:contact@piercetechnology.co.uk" className={text}>
          <Typography variant="body1">{t(`${tPrefixDigital}.text`)}</Typography>
        </a>
      </aside>
    </section>
  );
};
export default React.memo(Contact);
