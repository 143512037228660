import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './assets/styles';

const Banner: FC = () => {
  const { banner, aside, header, logo, contact, title, subtitle } = useStyles();
  const { t } = useTranslation();
  const tPrefix = 'landing.banner';

  return (
    <section className={banner}>
      <header className={header}>
        <span className={logo}>{t(`${tPrefix}.pierce`)}</span>
        <a className={contact} href="mailto:contact@piercetechnology.co.uk">{t(`${tPrefix}.contact`)}</a>
      </header>
      <aside className={aside}>
        <Typography variant="h1" className={title}>
          {t(`${tPrefix}.title`)}
        </Typography>
        <Typography variant="subtitle1" className={subtitle}>
          {t(`${tPrefix}.subtitle`)}
        </Typography>
      </aside>
    </section>
  );
};
export default React.memo(Banner);
