import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './assets/styles';

const About: FC = () => {
  const { content, subtitle, title, text } = useStyles();
  const { t } = useTranslation();
  const tPrefix = 'landing.aboutUs';

  return (
    <section className={content}>
      <Typography variant="h5" className={title}>
        {t(`${tPrefix}.title`)}
      </Typography>
      <Typography variant="h3" className={subtitle}>
        {t(`${tPrefix}.subtitle`)}
      </Typography>
      <Typography variant="subtitle2" className={text}>
        {t(`${tPrefix}.text`)}
      </Typography>
    </section>
  );
};
export default React.memo(About);
