import { createStyles, makeStyles } from '@material-ui/core/styles';

import banner from './banner.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      position: 'relative',
      width: '100%',
      height: 770,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${banner})`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.down('md')]: {
        height: 620,
      },
    },
    aside: {
      padding: '0 10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      position: 'absolute',
      width: '100%',
      maxWidth: 1120,
      top: 63,
      padding: '0 30px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        top: 40,
      },
    },
    logo: {
      fontFamily: 'mazzardm_semibold',
      fontSize: 12,
      lineHeight: '14px',
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
    contact: {
      fontFamily: 'mazzardm_medium',
      fontSize: 16,
      lineHeight: '19px',
      textDecoration: 'none',
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
    title: {
      marginBottom: 15,
      lineHeight: '52px',
      textAlign: 'center',
    },
    subtitle: {
      maxWidth: 440,
      textAlign: 'center',
    },
  }),
);

export default useStyles;
