import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      width: '100%',
      maxWidth: 1100,
      margin: '0 auto',
      padding: '60px 0 0',
      [theme.breakpoints.down('md')]: {
        padding: '60px 30px 0',
      },
    },
    title: {
      marginBottom: 30,
      [theme.breakpoints.down('md')]: {
        fontFamily: 'mazzardm_semibold',
        fontSize: 20,
        lineHeight: '24px',
      },
    },
    ul: {
      listStyle: 'none',
      margin: '0 -17px',
      width: 'calc(100% + 34px)',
      [theme.breakpoints.down('md')]: {
        margin: '0 -10px',
        width: 'calc(100% + 20px)',
      },
    },
    li: {
      padding: '0 17px',
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
        padding: '0 10px',
        marginBottom: 30,
      },
      '&:after': {
        content: '""',
        width: '100%',
        display: 'block',
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      },
    },
    itemTitle: {
      margin: '23px 0 25px',
      [theme.breakpoints.down('md')]: {
        margin: '20px 0 16px',
        fontSize: 16,
        lineHeight: '19px',
      },
    },
    itemIcon: {
      [theme.breakpoints.down('md')]: {
        width: 16,
        height: 16,
      },
    },
  }),
);

export default useStyles;
