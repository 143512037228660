import React from 'react';

import Banner from '../components/landing/banner';
import About from '../components/landing/about';
import Achievements from '../components/landing/achievements';
import Development from '../components/landing/development';
import Contact from '../components/landing/contact';

const LandingPage: React.FC = () => {
  return (
    <>
      <Banner />
      <About />
      <Achievements />
      <Development />
      <Contact />
    </>
  );
};

export default LandingPage;
