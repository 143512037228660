import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './assets/styles';

const Footer: FC = () => {
  const { footer, pierce, right, contact } = useStyles();
  const { t } = useTranslation();
  const tPrefix = 'footer';

  return (
    <footer className={footer}>
      <span className={pierce}>{t(`${tPrefix}.pierce`)}</span>
      <span className={right}>{t(`${tPrefix}.right`)}</span>
      <a className={contact} href="mailto:contact@piercetechnology.co.uk">
        {t(`${tPrefix}.contact`)}
      </a>
    </footer>
  );
};
export default React.memo(Footer);
