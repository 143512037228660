import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      width: '100%',
      maxWidth: 1100,
      margin: '0 auto',
      padding: '60px 0 0',
      [theme.breakpoints.down('md')]: {
        padding: '40px 30px 0',
      },
    },
    title: {
      [theme.breakpoints.down('md')]: {
        height: 30,
      },
    },
    subtitle: {
      margin: '10px 0 6px',
      [theme.breakpoints.down('md')]: {
        margin: '16px 0 10px',
        fontFamily: 'mazzardm_semibold',
        fontSize: 18,
        lineHeight: '21px',
      },
    },
    text: {
      maxWidth: 440,
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
  }),
);

export default useStyles;
