import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      position: 'relative',
      width: '100%',
      maxWidth: 725,
      margin: '70px auto',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        margin: '10px auto 90px',
        flexDirection: 'column',
        padding: '0 30px',
      },
      '&:after': {
        position: 'absolute',
        content: '""',
        width: 0,
        height: 'calc(100% - 20px)',
        borderLeft: `1px solid ${theme.palette.primary.light}`,
        borderBottom: 0,
        top: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('md')]: {
          width: 147,
          height: 0,
          borderLeft: 0,
          borderBottom: `1px solid ${theme.palette.primary.light}`,
          top: 152,
        },
      },
    },
    address: {
      fontStyle: 'normal',
      [theme.breakpoints.down('md')]: {
        marginBottom: 51,
      },
    },
    title: {
      marginBottom: 23,
      [theme.breakpoints.down('md')]: {
        height: 30,
        marginBottom: 16,
      },
    },
    text: {
      textAlign: 'center',
      textDecoration: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
  }),
);

export default useStyles;
