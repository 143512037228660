import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './assets/styles';

const Achievements: FC = () => {
  const { content, title, ul, li, itemTitle, itemText } = useStyles();
  const { t } = useTranslation();
  const tPrefix = 'landing.achievements';
  const list: any[] = t(`${tPrefix}.list`, { returnObjects: true });

  return (
    <section className={content}>
      <Typography variant="h2" className={title}>
        {t(`${tPrefix}.title`)}
      </Typography>
      <Grid container component="ul" className={ul}>
        {list.map((item) => (
          <Grid sm={12} md={4} item component="li" key={item.title} className={li}>
            <Typography variant="h4" className={itemTitle}>
              {item.title}
            </Typography>
            <Typography variant="subtitle2" className={itemText}>
              {item.text}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
export default React.memo(Achievements);
