import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      width: '100%',
      maxWidth: 1100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      paddingBottom: 67,
      [theme.breakpoints.down('md')]: {
        padding: '0 30px 33px',
      },
    },
    pierce: {
      fontFamily: 'mazzardm_semibold',
      fontSize: 12,
      lineHeight: '14px',
      color: theme.palette.primary.main,
    },
    right: {
      paddingLeft: 61,
      flex: 1,
      fontFamily: 'mazzardm_regular',
      fontSize: 12,
      lineHeight: '28px',
      color: theme.palette.primary.light,
      [theme.breakpoints.down('md')]: {
        textAlign: 'right',
      },
    },
    contact: {
      fontFamily: 'mazzardm_medium',
      fontSize: 12,
      lineHeight: '14px',
      textDecoration: 'none',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  }),
);

export default useStyles;
