import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './assets/styles';
import icon0 from './assets/0.svg';
import icon1 from './assets/1.svg';
import icon2 from './assets/2.svg';
import icon3 from './assets/3.svg';
import icon4 from './assets/4.svg';
import icon5 from './assets/5.svg';
import icon6 from './assets/6.svg';
import icon7 from './assets/7.svg';
import { Grid, Typography } from '@material-ui/core';

const Development: FC = () => {
  const { content, title, ul, li, itemTitle, itemIcon } = useStyles();
  const { t } = useTranslation();
  const tPrefix = 'landing.development';
  const list: any[] = t(`${tPrefix}.list`, { returnObjects: true });
  const handleIcon = (index: number) => {
    switch (index) {
      case 0:
        return icon0;
      case 1:
        return icon1;
      case 2:
        return icon2;
      case 3:
        return icon3;
      case 4:
        return icon4;
      case 5:
        return icon5;
      case 6:
        return icon6;
      default:
        return icon7;
    }
  };

  return (
    <section className={content}>
      <Typography variant="h2" className={title}>
        {t(`${tPrefix}.title`)}
      </Typography>
      <Grid container component="ul" className={ul}>
        {list.map((item, index) => (
          <Grid xs={6} md={3} item component="li" key={item} className={li}>
            <img src={handleIcon(index)} alt={item} className={itemIcon} />
            <Typography variant="body2" className={itemTitle}>
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
export default React.memo(Development);
