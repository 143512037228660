import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    h1: { fontFamily: 'mazzardm_bold', fontSize: 44, lineHeight: '70px', color: '#0B1232' },
    h2: { fontFamily: 'mazzardm_semibold', fontSize: 24, lineHeight: '70px', color: '#FFFFFF' },
    h3: { fontFamily: 'mazzardm_bold', fontSize: 16, lineHeight: '36px', color: '#FFFFFF' },
    h4: { fontFamily: 'mazzardm_bold', fontSize: 16, lineHeight: '46px', color: '#FFFFFF' },
    h5: {
      fontFamily: 'mazzardm_bold',
      fontSize: 10,
      lineHeight: '50px',
      color: '#6AB0D1',
      letterSpacing: '2.14286px',
      textTransform: 'uppercase',
    },
    subtitle1: { fontFamily: 'mazzardm_regular', fontSize: 16, lineHeight: '26px', color: '#0B1232', opacity: 0.78 },
    subtitle2: { fontFamily: 'mazzardm_light', fontSize: 12, lineHeight: '24px', color: '#9AD1EB' },
    caption: {
      fontFamily: 'mazzardm_bold',
      fontSize: 10,
      lineHeight: '50px',
      color: '#9AD1EB',
      letterSpacing: '2.14286px',
      textTransform: 'uppercase',
    },
    body1: { fontFamily: 'mazzardm_regular', fontSize: 12, lineHeight: '24px', color: '#9AD1EB' },
    body2: { fontFamily: 'mazzardm_medium', fontSize: 12, lineHeight: '30px', color: '#9AD1EB' },
    fontFamily: ['mazzardm_light', 'mazzardm_regular', 'mazzardm_medium', 'mazzardm_semibold', 'mazzardm_bold'].join(
      ',',
    ),
  },
  palette: {
    primary: {
      dark: '#0B1232',
      light: '#6AB0D1',
      main: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#FFFFFF',
    },
  },
});
