import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      width: '100%',
      maxWidth: 1100,
      margin: '0 auto',
      padding: '60px 0 0',
      [theme.breakpoints.down('md')]: {
        padding: '40px 30px 0',
      },
    },
    title: {
      marginBottom: 30,
      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
        fontSize: 20,
      },
    },
    ul: {
      listStyle: 'none',
      margin: '0 -17px',
      width: 'calc(100% + 34px)',
    },
    li: {
      padding: '0 17px',
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
        marginBottom: 20,
      },
    },
    itemTitle: {
      marginBottom: 6,
      [theme.breakpoints.down('md')]: {
        fontFamily: 'mazzardm_semibold',
        marginBottom: 10,
        height: 33,
      },
    },
    itemText: {
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
  }),
);

export default useStyles;
